<template>
    <div
        class="review-skeleton"
    >
        <div class="review-skeleton__header">
            <div class="review-skeleton--avatar" />
            <div class="review-skeleton__header--content">
                <span class="w-50" />
                <span class="w-25" />
            </div>
        </div>
        <div class="review-skeleton__body">
            <span class="w-50" />
            <span class="w-100" />
            <span class="w-25" />
            <span class="w-75" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReviewSkeleton',
    props: {
        reviews: Number
    }
}
</script>

<style scoped lang="scss">
    .review-skeleton {
        width: 100%;
        .w-100 {
           width: 100% !important;
        }
        .w-75 {
            width: 75% !important;
        }
        .w-50 {
            width: 50% !important;
        }
        .w-25 {
            width: 25% !important;
        }
        &__header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &--content {
                display: flex;
                flex-direction: column;
                width: calc(100% - 46px);
            }
            span {
                margin: 5px 0!important;
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
        }
        &--avatar {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            overflow: hidden;
            vertical-align: middle;
            background-color: #DDDBDD;
            margin: 10px;
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                        90deg,
                        rgba(#fff, 0) 0,
                        rgba(#fff, 0.2) 20%,
                        rgba(#fff, 0.5) 60%,
                        rgba(#fff, 0)
                );
                animation: shimmer 1s infinite;
                content: '';
            }
        }
        span {
            display: inline-block;
            height: 10px;
            width: 100%;
            position: relative;
            overflow: hidden;
            vertical-align: middle;
            background-color: #DDDBDD;
            margin: 10px 0 10px 0px;
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                        90deg,
                        rgba(#fff, 0) 0,
                        rgba(#fff, 0.2) 20%,
                        rgba(#fff, 0.5) 60%,
                        rgba(#fff, 0)
                );
                animation: shimmer 1s infinite;
                content: '';
            }
        }
    }
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
</style>
