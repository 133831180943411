<template>
    <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.6666 8.78004L15.2783 8.05671L11.9999 0.333374L8.72159 8.06837L0.333252 8.78004L6.70325 14.2984L4.78992 22.5L11.9999 18.1484L19.2099 22.5L17.3083 14.2984L23.6666 8.78004ZM11.9999 15.9667L7.61325 18.615L8.77992 13.6217L4.90659 10.2617L10.0166 9.81837L11.9999 5.11671L13.9949 9.83004L19.1049 10.2734L15.2316 13.6334L16.3983 18.6267L11.9999 15.9667Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'Star'
}
</script>

<style scoped>

</style>
