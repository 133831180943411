<template>
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.2375 8L13 11.2375L9.7625 8L8 9.7625L11.2375 13L8 16.2375L9.7625 18L13 14.7625L16.2375 18L18 16.2375L14.7625 13L18 9.7625L16.2375 8ZM13 0.5C6.0875 0.5 0.5 6.0875 0.5 13C0.5 19.9125 6.0875 25.5 13 25.5C19.9125 25.5 25.5 19.9125 25.5 13C25.5 6.0875 19.9125 0.5 13 0.5ZM13 23C7.4875 23 3 18.5125 3 13C3 7.4875 7.4875 3 13 3C18.5125 3 23 7.4875 23 13C23 18.5125 18.5125 23 13 23Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'StatusNo'
}
</script>

<style scoped>

</style>
