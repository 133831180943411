<template>
    <modal-layout
        v-if="dialog"
        :name="name"
        :size="'medium'"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        class="reviews-modal"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>Reviews</h2>
        </template>
        <template #content>
            <template v-if="loading">
                <review-skeleton
                    v-for="i in 2"
                    :key="i"
                />
            </template>
            <template v-else>
                <review
                    v-for="(review, id) in reviews"
                    :key="id"
                    :review="review"
                />
            </template>
            <confirm-modal
                :name="'confirm-review-delete'"
                title="Delete review?"
                content="Are you sure?"
                class="confirm-modal"
                @onConfirmClick="deleteReview($event)"
            />
        </template>
        <template #buttons>
            <custom-button
                v-if="routeName"
                default
                type="button"
                class="review-button orange-button"
                @on-btn-click="openFeedbackModal"
            >
                <star />
                Rate
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import {
    ACTION_GET_REVIEWS,
    ACTION_DELETE_REVIEW
} from '@/store/modules/content/reviews/action-types'
import { createNamespacedHelpers } from 'vuex';
import Star from '@/icons/Star';
import ConfirmModal from './ConfirmModal';
import Review from './components/Reviews/Review';
import ReviewSkeleton from './components/Reviews/ReviewSkeleton';

const {
    mapActions: mapReviewsActions
} = createNamespacedHelpers('reviews');

export default {
    name: 'ReviewsModal',
    components: {
        Review,
        ReviewSkeleton,
        Star,
        ConfirmModal
    },
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        name: String
    },
    data() {
        return {
            reviews: null,
            loading: false
        }
    },
    computed: {
        routeName() {
            return this.$route.name.split('-').shift() === 'revisions'
        }
    },
    watch: {
        data() {
            this.getReviews()
        }
    },
    methods: {
        ...mapReviewsActions([
            ACTION_GET_REVIEWS,
            ACTION_DELETE_REVIEW
        ]),
        async getReviews() {
            this.loading = true
            const response = await this[ACTION_GET_REVIEWS]({
                revision_id: this.data.post.rev_id,
                type: this.data.post.type
            })
            this.reviews = response.data
            this.loading = false
        },
        openFeedbackModal() {
            this.cancelModal()
            this.$bus.$emit('openModal', 'feedback-modal', {
                post: {
                    ...this.data,
                    rev_id: this.data.post.rev_id,
                    type: this.data.post.type
                }
            })
        },
        async deleteReview(event) {
            console.log('event', event)
            await this[ACTION_DELETE_REVIEW]({
                id: event.review.review_id,
                revision_id: this.data.post.rev_id,
                revision_type: this.data.post.type,
                post_id: this.data.post.post_id
            })
            this.reviews = this.reviews.filter((review) => review.id !== event.review.review_id)
            if (!this.reviews.length) this.hideModal('reviews-modal')
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal {
        .v-modal__content {
            max-height: 600px;
            overflow: scroll;
        }
        .v-modal__buttons {
            justify-content: center !important;
            .review-button {
                margin-left: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    margin-right: 6px;
                    margin-top: -3px;
                }
            }
        }
    }
    .reviews-modal {
        .v-modal__content {
            max-height: 600px;
            justify-content: flex-start!important;
            overflow: scroll;
        }
        .v-modal__buttons {
            justify-content: flex-start;
            .review-button {
                margin-left: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 100px;
                svg {
                    margin-right: 6px;
                    margin-top: -3px;
                }
            }
        }
    }
</style>
