<template>
    <div class="review">
        <div class="review-header">
            <div class="review-header__user">
                <div class="review-header__user--block">
                    <img
                        v-if="review.user_id && review.avatar_url"
                        :src="review.avatar_url"
                        class="review-header__user--avatar"
                        alt=""
                    >
                    <div
                        v-else
                        class="navbaravatar__letters"
                    >
                        {{ avatarLetter }}
                    </div>
                    <div class="review-header__user--info">
                        <p class="review-header__user--info_name">
                            {{ review.firstname }} {{ review.lastname }}
                        </p>
                        <p class="review-header__user--info_time">
                            {{ review.created_at | moment_from }} ago
                        </p>
                    </div>
                </div>
                <div class="review-header__actions">
                    <div
                        class="review-header__user--rating"
                        :class="getRatingClass"
                    >
                        {{ rating }}
                    </div>
                    <div
                        class="review-header__actions--delete"
                    >
                        <span
                            v-if="routeName && user.id === review.user_id || isSuperAdmin"
                            @click="openDeleteModal"
                        >
                            <garbage />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="messages"
            class="review-content"
        >
            <div
                v-for="(message, id) in messages"
                :key="id"
            >
                <p class="review-content__title">
                    {{ message.title }}
                    <status-ok-bold
                        v-if="message.status"
                        class="status-ok"
                    />
                    <status-no
                        v-else
                        class="status-no"
                    />
                </p>
                <div
                    v-if="message.text"
                    class="review-content__message"
                >
                    {{ message.text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Garbage from '@/icons/Garbage';
import StatusOkBold from '@/icons/StatusOkBold';
import StatusNo from '@/icons/StatusNo';
import filtersMixin from '@/mixins/filtersMixin'
import { mapState } from 'vuex'

export default {
    name: 'Review',
    components: {
        Garbage,
        StatusOkBold,
        StatusNo
    },
    mixins: [
        filtersMixin
    ],
    props: {
        review: Object
    },
    data() {
        return {
            ratingFields: ['chk_links', 'chk_content', 'chk_grammar', 'chk_photo'],
            messageFields: ['comment_links', 'comment_content', 'comment_grammar', 'comment_photo'],
            titleFields: {
                comment_links: 'Links',
                comment_content: 'Content',
                comment_grammar: 'Grammar',
                comment_photo: 'Photo'
            }
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        isSuperAdmin() {
            return !!this.user?.roles?.find((role) => role.name === 'super-admin')
        },
        avatarLetter() {
            if (this.review.user_id && this.review.firstname && this.review.lastname) {
                return this.user.firstname[0] + this.user.lastname[0]
            }
            return ''
        },
        routeName() {
            return this.$route.name.split('-').shift() === 'revisions'
        },
        rating() {
            let rating = null
            Object.keys(this.review).forEach((item) => {
                if (this.ratingFields.includes(item)) {
                    rating += this.review[item]
                }
            })
            return rating
        },
        getRatingStatus() {
            let status = null
            Object.keys(this.review).forEach((item) => {
                if (this.ratingFields.includes(item)) {
                    status = this.review[item]
                }
            })
            return status
        },
        messages() {
            const messages = []
            // eslint-disable-next-line array-callback-return,consistent-return
            this.messageFields.forEach((item) => {
                messages.push({
                    title: this.titleFields[item],
                    text: this.review[item],
                    status: !this.review[item]
                })
            })
            return messages
        },
        getRatingClass() {
            // eslint-disable-next-line no-nested-ternary
            return this.rating >= 3 ? 'green' : this.rating === 2 ? 'orange' : 'red'
        }
    },
    methods: {
        openDeleteModal() {
            this.$bus.$emit('openModal', 'confirm-review-delete', {
                review: {
                    review_id: this.review.id
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .v-modal {
        &__content {
            .review {
                width: 100%;
                padding-bottom: 22px;
                border-bottom: 1px solid #D3D9E2;
                padding-top: 20px;
                /*&:hover {*/
                /*    .review-header {*/
                /*        &__actions {*/
                /*            display: flex;*/
                /*        }*/
                /*    }*/
                /*}*/
                &:first-child {
                    padding-top: 0;
                }
                &-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &__user {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &--block {
                            display: flex;
                            align-items: center;
                        }
                        &--avatar {
                            width: 36px;
                            height: 36px;
                            margin-right: 8px;
                            border-radius: 50%;
                        }
                        .navbaravatar__letters{
                            margin-right: 8px;
                        }
                        &--info {
                            margin-right: 19px;
                            &_name {
                                margin: 0;
                                line-height: 1;
                                padding-top: 0;
                                font-weight: 500;
                                font-size: 14px;
                                color: #1F2939;
                            }
                            &_time {
                                margin: 0;
                                padding-top: 0;
                                font-size: 14px;
                                color: #818181;
                                line-height: 1;
                            }
                        }
                        &--rating {
                            &.green {
                                background: rgba(0, 232, 51, 0.1);
                                color: #219653;
                            }
                            &.orange {
                                background: $round-count;
                                color: $orange;
                            }
                            &.red {
                                background: rgba(255, 0, 0, 0.1);
                                color: #FF0000;
                            }
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                    }
                    &__actions {
                        display: flex;
                        align-items: center;
                        color: #4F4F4F;
                        svg {
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                            margin-left: 8px;
                        }
                        &--edit {
                            margin-right: 20px;
                        }
                        &--delete {
                            min-width: 28px;
                            transition: .3s;
                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                }
                &-content {
                    margin-top: 11px;
                    &__title {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        font-size: 14px;
                        line-height: 150%;
                        color: #818181;
                        svg {
                            margin-right: 36px;
                        }
                        .status {
                            &-ok {
                                color: #219653;
                            }
                            &-no {
                                color: #ff2400;
                            }
                        }
                    }
                    &__message {
                        margin-top: 8px;
                        font-size: 14px;
                        line-height: 150%;
                        text-align: left;
                        color: #1F2939;
                    }
                }
            }
        }
    }
</style>
